<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-09-04 17:29:27
 * @LastEditors: xiaoq
-->
<template>
  <div class="imgBox min-w-1400" v-if="!['/privacy'].includes($route.path)">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="clickBox1" @click="gotoPage1"></div>
        <div class="clickBox2" @click="gotoPage2"></div>
        <div class="clickBox3" @click="gotoPage3"></div>
        <div class="clickBox4" @click="gotoLink1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/markale/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
  methods: {
    // gotoPage1() {
    //   if (this.$route.path !== '/protocol') {
    //     this.$router.push({ path: '/protocol', query: { id: '1' } });
    //   }
    // },
    // gotoPage2() {
    //   if (this.$route.path !== '/protocol') {
    //     this.$router.push({ path: '/protocol', query: { id: '2' } });
    //   }
    // },
    // gotoPage3() {
    //   if (this.$route.path !== '/protocol') {
    //     this.$router.push({ path: '/protocol', query: { id: '3' } });
    //   }
    // },
    gotoTop() { window.scrollTo({ top: 0, behavior: "smooth" }); },
    gotoPage1() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        this.gotoTop();
      }
    },
    gotoPage2() {
      if (this.$route.path !== '/about') {
        this.$router.push('/about');
      } else {
        this.gotoTop();
      }
    },
    gotoPage3() {
      if (this.$route.path !== '/form') {
        this.$router.push('/form');
      } else {
        this.gotoTop();
      }
    },
    gotoLink1() {
      // window.open('https://www.mackale.com/#/privacy', '_blank');
      if (this.$route.path !== '/privacypolicy') {
        this.$router.push('/privacypolicy');
      } else {
        this.gotoTop();
      }
    },
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}

.img1Box {
  position: relative;
}

.clickBox1 {
  position: absolute;
  background: transparent;
  width: 100px;
  height: 43px;
  top: 25%;
  left: 15.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox2 {
  position: absolute;
  background: transparent;
  width: 132px;
  height: 43px;
  top: 39%;
  left: 15.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox3 {
  position: absolute;
  background: transparent;
  width: 100px;
  height: 43px;
  top: 52%;
  left: 15.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox4 {
  position: absolute;
  background: transparent;
  width: 242px;
  height: 43px;
  top: 67%;
  left: 15.5%;
  cursor: pointer;
  /* border: 1px solid red; */
}
</style>
