<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <div class="img1BoxContent">
          <div class="box1">
            <!-- <h1>Nuestro Servicio</h1> -->
            <ul>
              <li :class="{ clicked: currentTab === '1' }" @click="changeTab('1')">
                ข้อตกลงความเป็นส่วนตัว
              </li>
              <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '2' }" @click="changeTab('2')">
                ประเภทใบอนุญาต
              </li>
              <!-- <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '3' }" @click="changeTab('3')">
                หนังสือมอบอำนาจของบริษัท
              </li>
              <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '4' }" @click="changeTab('4')">
                คำชี้แจงการอนุญาตชื่อโดเมน
              </li> -->
            </ul>
          </div>
          <div class="rightImgBox2">
            <div class="textBox">
              <div class="textContext">
                {{ text1 }}
                ข้างต้นเป็นเอกสารที่รัฐบาลไทยออกให้แก่เรา: (<a href="https://www.mackale.com/image/11092024.jpg"
                  target="_blank">เอกสาร</a>)
                {{ text2 }}
              </div>
            </div>
          </div>
          <!-- <div v-show="currentTab === '1'" class="rightImgBox2">
            <div class="textBox">
              <div class="textContext">{{ text }}</div>
            </div>
          </div>
          <div v-show="currentTab === '2'" class="rightImgBox2">
            <img :src="importedImg1" alt="" class="imgItem1" />
            <img :src="importedImg2" alt="" class="imgItem2" />
          </div>
          <div v-show="currentTab === '3'" class="rightImgBox2">
            <img :src="importedImg3" alt="" class="imgItem3" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <!-- <img :src="importedImg2" alt="" class="imgItem2" /> -->
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <!-- <img :src="importedImg3" alt="" class="imgItem3" /> -->
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <!-- <img :src="importedImg4" alt="" class="imgItem4" /> -->
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
      </div>
    </div>
    <!-- <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <img :src="importedImg6" alt="" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7Box max-w-1400">
        <img :src="importedImg7" alt="" class="imgItem7" />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/markale/components/img/4_2_1.png";
import importedImg2 from "@/page/markale/components/img/4_2_2.png";
import importedImg3 from "@/page/markale/components/img/4_3_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      // importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      currentImage: importedImg1, // 初始化显示第一张图片
      currentTab: '1',
      altText: "图片",
      text1: `MACKALE GROUP PUBLIC COMPANY LIMITED
(ต่อไปนี้จะเรียกว่า "บริษัท", "เรา" หรือ "พวกเรา") เป็นผู้ให้บริการ Kale Lending (ต่อไปนี้จะเรียกว่า "ผลิตภัณฑ์" หรือ "บริการ") เราให้ความสำคัญกับการคุ้มครองความเป็นส่วนตัวของคุณเป็นอย่างมาก และเข้าใจถึงความสำคัญของข้อมูลส่วนบุคคล Kale Lending เป็นแอปพลิเคชั่นที่ออกแบบมาเพื่อมอบบริการสินเชื่อที่สะดวก ปลอดภัย และโปร่งใสแก่ผู้ใช้ เพื่อให้มั่นใจว่าคุณมีความเข้าใจอย่างถ่องแท้ถึงวิธีที่เราประมวลผลข้อมูลส่วนบุคคลของคุณ เราจึงเผยแพร่นโยบายความเป็นส่วนตัวนี้ (ต่อไปนี้จะเรียกว่า "นโยบายนี้") เพื่ออธิบายรายละเอียดว่าเรารวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของคุณอย่างไรตาม ด้วยกฎหมายและข้อบังคับที่เกี่ยวข้อง (ต่อไปนี้จะเรียกว่า "ข้อมูลส่วนบุคคล")


เอกสารที่ทางราชการออกให้
`,
      text2: `

เราสัญญาว่า:
- ข้อมูลส่วนบุคคลของคุณจะถูกรวบรวมและใช้ตามที่กฎหมายอนุญาตและด้วยความยินยอมอย่างชัดแจ้งจากคุณเท่านั้น
- ใช้มาตรการที่จำเป็นทั้งหมดเพื่อปกป้องข้อมูลส่วนบุคคลของคุณจากการเข้าถึง การเปิดเผย การเปลี่ยนแปลง หรือการทำลายโดยไม่ได้รับอนุญาต
- เคารพตัวเลือกความเป็นส่วนตัวของคุณและให้ตัวเลือกที่ชัดเจนแก่คุณในการควบคุมวิธีการใช้ข้อมูลส่วนบุคคลของคุณ
นโยบายนี้ใช้กับบริการทั้งหมดที่เรามอบให้ รวมถึงแต่ไม่จำกัดเพียงแอปพลิเคชันบนมือถือ เว็บไซต์ และบริการของเราที่ให้ผ่านช่องทางอื่นๆ เราขอแนะนำให้คุณอ่านนโยบายนี้อย่างละเอียดเพื่อทำความเข้าใจสิทธิ์ของคุณและความมุ่งมั่นของเรา

ความมุ่งมั่นของเรา
เราสัญญาว่า:
- แจ้งให้คุณทราบอย่างชัดเจนเกี่ยวกับข้อมูลส่วนบุคคลที่เรารวบรวมและวัตถุประสงค์ที่เรารวบรวม
- ตรวจสอบให้แน่ใจว่าคุณเข้าใจวิธีที่เราใช้ข้อมูลนี้เพื่อให้บริการและปรับปรุงบริการของเรา
- ให้สิทธิ์แก่คุณในการเข้าถึง การแก้ไข และการลบข้อมูลส่วนบุคคลของคุณ
- ตรวจสอบและอัปเดตนโยบายความเป็นส่วนตัวของเราเป็นประจำเพื่อสะท้อนถึงความมุ่งมั่นอย่างต่อเนื่องของเราต่อความเป็นส่วนตัวของผู้ใช้

ขอบเขตการใช้งาน
นโยบายนี้ใช้กับบริการทั้งหมดของเรา รวมถึงแต่ไม่จำกัดเพียงแอปพลิเคชันบนมือถือ เว็บไซต์ และบริการที่ให้ผ่านช่องทางอื่นๆ

เรารวบรวมข้อมูลของคุณอย่างไร
เพื่อให้บริการและปรับปรุงบริการของเรา เราอาจรวบรวมข้อมูลประเภทต่อไปนี้:
1. ข้อมูลที่ผู้ใช้ส่งมาเอง: เมื่อคุณแบ่งปันข้อมูลกับเราอย่างจริงจัง เช่น เมื่อลงทะเบียนบัญชี กรอกแบบฟอร์ม ยื่นใบสมัครสินเชื่อ หรือการเข้าร่วมการสำรวจ เราจะรวบรวมข้อมูลที่คุณให้ไว้ ข้อมูลนี้อาจรวมถึงชื่อ ที่อยู่ หมายเลขโทรศัพท์ ที่อยู่อีเมล ข้อมูลทางการเงิน และข้อมูลอื่น ๆ ที่คุณพบว่ามีประโยชน์เกี่ยวกับบริการของเรา
2. การรวบรวมการอนุญาตที่ผู้ใช้อนุมัติ: เพื่อปรับปรุงประสบการณ์ผู้ใช้ของคุณ เราอาจขอให้คุณอนุญาตการเข้าถึงคุณสมบัติบางอย่างของอุปกรณ์ของคุณ ต่อไปนี้คือประเภทของสิทธิ์ที่เราอาจร้องขอ และวิธีที่เราใช้:
- กล้อง: หากคุณตกลงที่จะอนุญาต เราอาจเข้าถึงกล้องของคุณเพื่อถ่ายภาพหรือบันทึกวิดีโอ ซึ่งอาจใช้สำหรับการตรวจสอบสิทธิ์ การสแกนเอกสาร หรือฟังก์ชั่นอื่น ๆ ที่ต้องใช้การป้อนข้อมูลรูปภาพ
- อัลบั้มรูปภาพ: หากคุณอนุญาตให้เข้าถึง เราอาจเลือกหรืออัปโหลดรูปภาพจากอัลบั้มรูปภาพของคุณ ซึ่งอาจใช้เพื่ออัปโหลดข้อมูลประจำตัว เอกสารทางการเงิน หรือเอกสารอื่น ๆ ที่เกี่ยวข้อง
- ตำแหน่ง: หากคุณตกลงที่จะแบ่งปันข้อมูลตำแหน่งของคุณ เราอาจรวบรวมข้อมูลตำแหน่งของคุณเพื่อให้บริการตามตำแหน่ง เช่น การค้นหาจุดบริการที่ใกล้ที่สุดหรือคำแนะนำเนื้อหาที่ปรับแต่งเอง
- ข้อมูลการติดต่อ: หากคุณอนุญาตให้เข้าถึงข้อมูลการติดต่อของคุณ เราอาจรวบรวมข้อมูลนี้เพื่อให้คุณเชิญเพื่อนมาใช้บริการของเรา หรือเพื่อช่วยเรายืนยันตัวตนของคุณเมื่อจำเป็น
เราสัญญาว่าเราจะรวบรวมข้อมูลนี้เฉพาะเมื่อได้รับความยินยอมจากคุณอย่างชัดแจ้งเท่านั้น เราใช้ข้อมูลนี้เพื่อมอบและปรับปรุงบริการของเรา ปรับแต่งประสบการณ์ของคุณ ดำเนินการบริการลูกค้าและสนับสนุน ดำเนินการวิจัยและวิเคราะห์ข้อมูล และบังคับใช้ภาระผูกพันทางกฎหมายของเรา

วิธีที่เราใช้ข้อมูลของคุณ
ข้อมูลที่เรารวบรวมอาจถูกนำไปใช้ในลักษณะต่อไปนี้:
1. ให้บริการและปรับปรุงบริการ: เราใช้ข้อมูลของคุณเพื่อให้บริการที่คุณร้องขอ เช่น การประมวลผลการสมัครสินเชื่อ การให้การสนับสนุนลูกค้า การตอบคำถาม หรือการปรับแต่งประสบการณ์ผู้ใช้ของคุณให้เป็นแบบส่วนตัว
2. ประสบการณ์เฉพาะบุคคล: เราอาจใช้ข้อมูลของคุณเพื่อทำความเข้าใจการตั้งค่าและความต้องการของคุณ เพื่อให้เราสามารถนำเสนอเนื้อหา ผลิตภัณฑ์ หรือบริการที่สอดคล้องกับความสนใจของคุณมากขึ้น
3. ความปลอดภัยและการยืนยันตัวตน: เราอาจใช้ข้อมูลของคุณเพื่อยืนยันตัวตนของคุณ รับประกันความปลอดภัยของบริการ ป้องกันการฉ้อโกงและการละเมิด และปกป้องคุณและผู้ใช้รายอื่นของเรา
4. การสื่อสารและการตลาด: เราอาจใช้ข้อมูลติดต่อของคุณเพื่อสื่อสารกับคุณเพื่อให้ข้อมูลอัปเดต ประกาศ โปรโมชั่น หรือการสำรวจเกี่ยวกับบริการของเรา
5. การวิจัยและการวิเคราะห์ข้อมูล: เราอาจใช้ข้อมูลของคุณเพื่อทำการวิจัยภายในเพื่อปรับปรุงบริการของเรา แก้ไขปัญหาทางเทคนิค และให้การสนับสนุนข้อมูลสำหรับการตัดสินใจทางธุรกิจของเรา
6. ปฏิบัติตามกฎหมายและสิทธิในการปกป้อง: เราอาจใช้ข้อมูลของคุณเพื่อปฏิบัติตามกฎหมาย ข้อบังคับ กระบวนการทางกฎหมาย หรือคำขอของรัฐบาล เพื่อปกป้องสิทธิ ทรัพย์สิน หรือความปลอดภัยของเรา และเพื่อบังคับใช้ข้อกำหนดและเงื่อนไขของเรา
7. วัตถุประสงค์เฉพาะอื่นๆ: เราอาจใช้ข้อมูลของคุณตามความยินยอมของคุณหรือเหตุผลอื่นที่กฎหมายอนุญาต
เราสัญญาว่าจะไม่ใช้ข้อมูลของคุณเพื่อวัตถุประสงค์ใด ๆ ที่ไม่ได้ระบุไว้อย่างชัดแจ้งในนโยบายนี้ เราแบ่งปันข้อมูลของคุณกับบุคคลที่สามภายใต้สถานการณ์ต่อไปนี้เท่านั้น:
- คุณยินยอมอย่างชัดแจ้งให้เราทำเช่นนี้
- มีความจำเป็นเพื่อให้บริการตามที่คุณร้องขอ
- จำเป็นต้องปฏิบัติตามกฎหมาย ข้อบังคับ หรือตามคำขอของรัฐบาลที่ชอบด้วยกฎหมาย
- มีความจำเป็นต้องปกป้องสิทธิ์ ทรัพย์สิน หรือความปลอดภัยของเราหรือของผู้ใช้ของเรา

วิธีที่เราประมวลผลและถ่ายโอนข้อมูลของคุณ
เราให้ความสำคัญกับการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณอย่างจริงจัง และใช้มาตรการต่อไปนี้เพื่อปกป้องข้อมูลของคุณ:
1. การลดขนาดข้อมูล: เรารวบรวมเฉพาะข้อมูลส่วนบุคคลขั้นต่ำที่จำเป็นต่อการให้บริการของเราเท่านั้น
2. ความปลอดภัยของข้อมูล: เราใช้เทคโนโลยีและขั้นตอนความปลอดภัยมาตรฐานอุตสาหกรรมเพื่อปกป้องข้อมูลส่วนบุคคลของคุณจากการเข้าถึงและการเปิดเผยโดยไม่ได้รับอนุญาต
3. การควบคุมการเข้าถึง: เราจำกัดการเข้าถึงข้อมูลส่วนบุคคลไว้เฉพาะบุคลากรที่ได้รับอนุญาตเท่านั้น และข้อมูลที่จำเป็นในการปฏิบัติหน้าที่ของพวกเขา
4. การเก็บรักษาข้อมูล: เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้นานเท่าที่จำเป็นเพื่อให้บริการของเราเท่านั้น เมื่อเราไม่ต้องการข้อมูลอีกต่อไป เราจะลบหรือปิดบังข้อมูลนั้นอย่างปลอดภัย
5. การถ่ายโอนข้อมูล: เราอาจถ่ายโอนข้อมูลส่วนบุคคลไปยังสถานที่นอกประเทศ/ภูมิภาคของคุณเพื่อการประมวลผลและการจัดเก็บ ในกรณีเช่นนี้ เราจะใช้มาตรการป้องกันที่เหมาะสม เช่น ผ่านข้อสัญญามาตรฐานของสหภาพยุโรปหรือกลไกการป้องกันที่คล้ายกัน เพื่อให้แน่ใจว่าข้อมูลของคุณได้รับการคุ้มครองในระดับเดียวกับในประเทศของคุณ
6. ข้อกำหนดทางกฎหมาย: เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณตามข้อกำหนดทางกฎหมาย คำสั่งศาล หรือกระบวนการทางกฎหมายอื่น ๆ
7. ผู้ให้บริการบุคคลที่สาม: เราอาจร่วมมือกับผู้ให้บริการบุคคลที่สามเพื่อสนับสนุนการดำเนินธุรกิจของเรา เช่น บริการจัดเก็บข้อมูลบนคลาวด์ การวิเคราะห์ข้อมูล การประมวลผลการชำระเงิน เป็นต้น เมื่อเลือกผู้ให้บริการเหล่านี้ เราจะดำเนินการตรวจสอบสถานะอย่างเหมาะสมและกำหนดให้พวกเขาปฏิบัติตามมาตรฐานความเป็นส่วนตัวและความปลอดภัยเช่นเดียวกับของเรา
8. การละเมิดข้อมูล: หากเราค้นพบการละเมิดความปลอดภัย เราจะดำเนินการทันทีเพื่อปกป้องข้อมูลของคุณและแจ้งให้คุณทราบและหน่วยงานกำกับดูแลที่เกี่ยวข้องตามที่จำเป็นตามที่กฎหมายกำหนด
เรามุ่งมั่นที่จะปฏิบัติตามกฎหมายและข้อบังคับด้านความเป็นส่วนตัวที่เกี่ยวข้องทั้งหมดเมื่อประมวลผลและถ่ายโอนข้อมูลส่วนบุคคลของคุณ รวมถึงแต่ไม่จำกัดเพียงกฎหมายคุ้มครองความเป็นส่วนตัวของประเทศไทยและข้อกำหนดของ Apple App Store

สิทธิของผู้ใช้
เราเคารพและสนับสนุนสิทธิ์ของคุณในข้อมูลส่วนบุคคลของคุณ ภายใต้กฎหมายและข้อบังคับความเป็นส่วนตัวที่บังคับใช้ คุณอาจมีสิทธิ์ดังต่อไปนี้:
1. สิทธิ์ในการเข้าถึง: คุณมีสิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคลของคุณและเรียนรู้ว่าเรารวบรวมข้อมูลใดบ้างและนำไปใช้อย่างไร
2. สิทธิ์ในการแก้ไข: หากข้อมูลส่วนบุคคลของคุณไม่ถูกต้องหรือไม่สมบูรณ์ คุณมีสิทธิ์ขอให้เราแก้ไขหรืออัปเดตข้อมูล
3. สิทธิ์ในการลบ: คุณมีสิทธิ์ขอให้เราลบข้อมูลส่วนบุคคลของคุณภายใต้สถานการณ์บางอย่าง เช่น เมื่อข้อมูลนั้นไม่จำเป็นสำหรับวัตถุประสงค์ในการเก็บรวบรวมอีกต่อไป
4. สิทธิ์ในการเพิกถอนความยินยอม: หากคุณได้ตกลงให้เราใช้ข้อมูลส่วนบุคคลของคุณก่อนหน้านี้ คุณมีสิทธิ์ที่จะเพิกถอนความยินยอมของคุณได้ตลอดเวลา แต่สิ่งนี้จะไม่ส่งผลกระทบต่อการประมวลผลที่เราได้ดำเนินการก่อนที่คุณจะเพิกถอนความยินยอมของคุณ
5. สิทธิ์ในการเคลื่อนย้ายข้อมูล: คุณมีสิทธิ์รับข้อมูลส่วนบุคคลของคุณในรูปแบบที่มีโครงสร้าง ใช้กันทั่วไป และอ่านได้ด้วยเครื่อง และมีสิทธิ์ในการถ่ายโอนข้อมูลนี้ไปยังผู้ให้บริการรายอื่น
6. สิทธิ์ในการคัดค้าน: คุณมีสิทธิ์คัดค้านการประมวลผลข้อมูลส่วนบุคคลของคุณในบางสถานการณ์ โดยเฉพาะอย่างยิ่งเมื่อการประมวลผลนั้นขึ้นอยู่กับผลประโยชน์ที่ชอบด้วยกฎหมายของเรา
7. สิทธิ์ในการตัดสินใจโดยอัตโนมัติ: คุณมีสิทธิ์ที่จะไม่ได้รับผลกระทบจากการตัดสินใจโดยอิงจากการประมวลผลอัตโนมัติเพียงอย่างเดียวที่ก่อให้เกิดผลทางกฎหมายหรือมีผลกระทบอย่างมีนัยสำคัญต่อคุณในทำนองเดียวกัน
8. การคุ้มครองความเป็นส่วนตัวโดยการออกแบบและการปกป้องข้อมูลตามค่าเริ่มต้น: เรามุ่งมั่นที่จะใช้การคุ้มครองความเป็นส่วนตัวเป็นข้อพิจารณาหลักเมื่อออกแบบและพัฒนาบริการของเราและใช้มาตรการปกป้องข้อมูลตามค่าเริ่มต้น
เพื่อใช้สิทธิ์เหล่านี้ คุณสามารถติดต่อเราผ่านที่อยู่อีเมลที่เราให้ไว้: office@mackale.com หลังจากได้รับคำขอของคุณแล้ว เราจะตอบกลับทันทีและใช้มาตรการที่เหมาะสมตามข้อกำหนดของกฎหมายที่บังคับใช้


เราปกป้องข้อมูลของคุณอย่างไร
การปกป้องความปลอดภัยของข้อมูลส่วนบุคคลของคุณถือเป็นสิ่งสำคัญสูงสุดของเรา เราใช้มาตรการทางกายภาพ เทคนิค และการบริหารหลายอย่างเพื่อปกป้องข้อมูลของคุณจากการเข้าถึง การใช้ การเปิดเผย การเปลี่ยนแปลง หรือการทำลายโดยไม่ได้รับอนุญาต:
1. เทคโนโลยีการเข้ารหัส: เราใช้เทคโนโลยีการเข้ารหัสมาตรฐานอุตสาหกรรม เช่น SSL/TLS เพื่อปกป้องความปลอดภัยของการรับส่งข้อมูลระหว่างคุณและบริการของเรา
2. การควบคุมการเข้าถึง: เราใช้มาตรการควบคุมการเข้าถึงที่เข้มงวดเพื่อให้แน่ใจว่าเฉพาะบุคลากรที่ได้รับอนุญาตเท่านั้นที่สามารถเข้าถึงข้อมูลส่วนบุคคลของคุณ และพวกเขาสามารถเข้าถึงเฉพาะข้อมูลที่จำเป็นต่อการทำงานให้เสร็จสิ้นเท่านั้น
3. ความปลอดภัยในการจัดเก็บข้อมูล: ระบบจัดเก็บข้อมูลของเราใช้มาตรการรักษาความปลอดภัย เช่น ไฟร์วอลล์ ระบบตรวจจับการบุกรุก และการตรวจสอบความปลอดภัยเป็นประจำ เพื่อป้องกันการเข้าถึงโดยไม่ได้รับอนุญาต
4. การฝึกอบรมพนักงาน: พนักงานของเราได้รับการฝึกอบรมด้านการคุ้มครองความเป็นส่วนตัวและความปลอดภัยของข้อมูล และเข้าใจถึงความรับผิดชอบในการปกป้องข้อมูลผู้ใช้
5. นโยบายการเก็บรักษาข้อมูล: เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตราบเท่าที่จำเป็นเพื่อการให้บริการหรือปฏิบัติตามข้อกำหนดทางกฎหมาย จากนั้นเราจะดำเนินการตามขั้นตอนที่เหมาะสมเพื่อลบหรือปิดบังข้อมูลนี้อย่างปลอดภัย
6. การตอบสนองต่อการละเมิดข้อมูล: เรามีแผนการตอบสนองต่อการละเมิดข้อมูล เมื่อเราพบความเสี่ยงของการละเมิดข้อมูล เราจะดำเนินมาตรการเพื่อปกป้องข้อมูลของคุณทันที และแจ้งให้คุณทราบและหน่วยงานกำกับดูแลที่เกี่ยวข้องตามที่กฎหมายที่เกี่ยวข้องกำหนด
7. การรักษาความปลอดภัยของบุคคลที่สาม: เราดำเนินการตรวจสอบความปลอดภัยอย่างเข้มงวดของผู้ให้บริการบุคคลที่สามทั้งหมด และกำหนดให้พวกเขาปฏิบัติตามมาตรฐานความเป็นส่วนตัวและความปลอดภัยเช่นเดียวกับของเรา
8. การประเมินความปลอดภัยเป็นประจำ: เราดำเนินการประเมินความปลอดภัยภายในและภายนอกอย่างสม่ำเสมอเพื่อให้แน่ใจว่ามาตรการรักษาความปลอดภัยของเรามีความทันสมัยและสามารถตอบสนองต่อภัยคุกคามทางไซเบอร์ที่เปลี่ยนแปลงไป

รายละเอียดสินค้าสินเชื่อ
วงเงินกู้สูงสุด 40,000 บาท
ส่วนของร่างกาย: 93 วัน ( ขั้นต่ำ - รวมระยะเวลา) - 365 วัน (สูงสุด - รวมระยะเวลา)
อัตราการเต้นของหัวใจ (APR): อุณหภูมิ 14%/ปี
ฟอร์มระบบ:0
14% ของเวลาที่จะต้องจ่ายดอกเบี้ย 120 วัน
รวมดอกเบี้ยเป็น 40,000 บาท * 0.14/365 * 120 = 1841 บาท
40,000 บาท * 0.14/365 * 30 = 460 บาท ท
ที่สามารถชำระได้ = 40,000 + ดอกเบี้ย = 40,000 + 1841 = 41,841 บาท
เดือนนี้ = 41,841/120 * 30 = 10,460 บาท

ติดต่อเรา
อีเมล์: office@mackale.com
ที่อยู่บริษัท: 45/360 หมู่ 6 ถนนลำลูกกา 12130 LUM LUK KA
ชื่อบริษัท : MACKALE GROUP PUBLIC COMPANY LIMITED

การปรับปรุงนโยบายความเป็นส่วนตัว
เราอาจอัปเดตนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราวเพื่อสะท้อนถึงการเปลี่ยนแปลงบริการของเรา การอัปเดตข้อกำหนดทางกฎหมาย หรือการปรับปรุงหลักปฏิบัติด้านความเป็นส่วนตัวของเรา การอัปเดตอาจรวมถึงวิธีปฏิบัติในการประมวลผลข้อมูลใหม่ การเปลี่ยนแปลงระยะเวลาการเก็บรักษาข้อมูล หรือการอัปเดตสิทธิ์และตัวเลือกของคุณ`,
    };
  },
  created() {
    this.gotoTop();
    if (this.$route.query.id) {
      this.currentTab = this.$route.query.id;
    }
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    changeImage(image) {
      this.currentImage = image;
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab === '2') {
        this.$router.push('/license');
      }
      if (tab === '3') {
        this.$router.push('/Authorization');
      }
      if (tab === '4') {
        this.$router.push('/businessStatement');
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img1BoxContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-left: 45px;
  margin-right: 45px; */
  /* position: relative; */
}

.rightImgBox2 {
  /* position: absolute; */
  /* padding: 100px 0; */
  padding-top: 40px;
  padding-bottom: 100px;
}

.box1 {
  min-width: 230px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  /* position: absolute;
  top: 60px;
  left: 40px; */
  padding: 10px 10px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  margin-left: 98px;
  margin-top: 40px;
  margin-right: 40px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  /* 添加指针样式以表示可点击 */
  color: #333;
  /* 设置默认字体颜色 */
  border-radius: 5px;
  padding: 15px 5px;
  text-align: center;
}

a {
  text-decoration: none;
}

a:hover {
  color: #ff6600;
  /* 设置链接悬停时的颜色 */
}

/* 新增样式：点击后的效果 */
li.clicked {
  background-color: #4263ff;
  /* 点击后背景色为蓝色 */
  color: #fff;
  /* 点击后字体颜色为白色 */
  border-radius: 15px 15px 0px 15px;
}

.img1Box {}

.imgItem1 {
  margin-top: 10px;
  margin-bottom: 40px;
}

.imgItem1,
.imgItem2 {
  width: 80%;
  margin-right: 70px;
}

.imgItem3 {
  width: 80%;
  margin-right: 174px;
}

.textBox {
  width: 80%;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 4400px;
  padding: 60px 100px 200px;
  font-size: 18px;
  color: #333333;
  /* height: 5500px; */
  height: 100%;
  margin-bottom: 200px;
}

.textContext {
  white-space: pre-wrap;
}

a {
  text-decoration: underline;
  color: #4285f4;
}

a:hover {
  text-decoration: underline;
}
</style>
