<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <div class="img1BoxContent">
          <div class="box1">
            <!-- <h1>Nuestro Servicio</h1> -->
            <ul>
              <li :class="{ clicked: currentTab === '1' }" @click="changeTab('1')">
                ข้อตกลงความเป็นส่วนตัว
              </li>
              <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '2' }" @click="changeTab('2')">
                ประเภทใบอนุญาต
              </li>
              <!-- <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '3' }" @click="changeTab('3')">
                หนังสือมอบอำนาจของบริษัท
              </li>
              <div style="border-bottom: 1px solid #E1E1E1;"></div>
              <li :class="{ clicked: currentTab === '4' }" @click="changeTab('4')">
                คำชี้แจงการอนุญาตชื่อโดเมน
              </li> -->
            </ul>
          </div>
          <div class="rightImgBox2">
            <img :src="importedImg1" alt="" class="imgItem1" />
            <img :src="importedImg2" alt="" class="imgItem2" />
            <div class="clickBox1" @click="gotoLink1"></div>
          </div>
          <!-- <div v-show="currentTab === '1'" class="rightImgBox2">
            <div class="textBox">
              <div class="textContext">{{ text }}</div>
            </div>
          </div>
          <div v-show="currentTab === '2'" class="rightImgBox2">
            <img :src="importedImg1" alt="" class="imgItem1" />
            <img :src="importedImg2" alt="" class="imgItem2" />
          </div>
          <div v-show="currentTab === '3'" class="rightImgBox2">
            <img :src="importedImg3" alt="" class="imgItem3" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <!-- <img :src="importedImg2" alt="" class="imgItem2" /> -->
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <!-- <img :src="importedImg3" alt="" class="imgItem3" /> -->
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <!-- <img :src="importedImg4" alt="" class="imgItem4" /> -->
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
      </div>
    </div>
    <!-- <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <img :src="importedImg6" alt="" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7Box max-w-1400">
        <img :src="importedImg7" alt="" class="imgItem7" />
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/markale/components/img/4_2_1.png";
import importedImg2 from "@/page/markale/components/img/4_2_2.png";
import importedImg3 from "@/page/markale/components/img/4_3_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      // importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      currentImage: importedImg1, // 初始化显示第一张图片
      currentTab: '2',
      altText: "图片",
      text: `MACKALE GROUP PUBLIC COMPANY LIMITED (“บริษัท”) ตระหนักถึงความสําคัญในความเป็นส่วนตัวและการคุ้มครองข้อมูลส่วนบุคคลของลูกค้า ผู้ใช้บริการ (รวมเรียกว่า “ท่าน”) บริษัทจึงได้จัดให้มีนโยบายการคุ้มครองข้อมูลส่วนบุคคล เพื่อแจ้งให้ท่านทราบเกี่ยวกับแนวทางปฏิบัติของบริษัทในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อให้ท่านสามารถใช้ บริการเงินอิเล็กทรอนิกส์ผ่านแอปพลิเคชัน Kwamdee plus (“แอปพลิเคชัน”) บริการรับชำระเงินแทน บริการโอนเงินด้วยวิธีการทางอิเล็กทรอนิกส์ และบริการอื่น ๆ ที่บริษัทได้รับอนุญาตจากธนาคารแห่งประเทศไทย ที่มีอยู่ปัจจุบันและที่อาจมีขึ้นในอนาคต รวมถึงแต่ไม่จำกัดเพียงบริการใดๆ ที่เกี่ยวข้องกับการใช้งานแอปพลิเคชัน เว็บไซต์ และช่องทางใดๆ ที่ให้บริการโดยบริษัท (รวมเรียกว่า “บริการ”) ได้อย่างมั่นใจ

ข้อมูลสินเชื่อ
วงเงินกู้ตั้งแต่ ฿500-฿40,000
ระยะเวลาเงินกู้: 93 ถึง 365 วัน
อัตราดอกเบี้ยเงินกู้: 0.01% ถึง 0.08% ต่อวัน ขึ้นอยู่กับประวัติเครดิตของคุณ อัตราดอกเบี้ยสูงสุดต่อปี (APR) 30%/ปี
ไม่มีค่าธรรมเนียมอื่น ๆ
เช่น กู้เงิน 1,000 บาท ภายใน 93 วัน ต้องเสียดอกเบี้ย 27.5 บาท รวมชำระคืน 1,027.5 บาท
*คำนวณจากอัตราดอกเบี้ยรายวัน 0.03% อัตราดอกเบี้ยรายปี: 10.8%

ข้อมูลส่วนบุคคล
ข้อมูลส่วนบุคคล หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งสามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ เช่น ชื่อ-นามสกุล เลขบัตรประชาชน ที่อยู่ หมายเลขโทรศัพท์ ข้อมูลทางการเงิน เป็นต้น
ข้อมูลส่วนบุคคลอาจประกอบด้วยข้อมูลที่มีความละเอียดอ่อนและเสี่ยงต่อการถูกใช้ในการเลือกปฏิบัติอย่างไม่เป็นธรรม (Sensitive Personal Data) ซึ่งข้อมูลส่วนบุคคลดังกล่าว รวมถึงแต่ไม่จำกัดอยู่เพียง ข้อมูลทางชีวภาพ ข้อมูลเกี่ยวกับศาสนา
ทั้งนี้ ข้อมูลส่วนบุคคล รวมถึงข้อมูลใด ๆ ของท่านที่ได้ให้ไว้แก่บริษัท และ/หรือที่อยู่ในความครอบครองของบริษัท และ/หรือที่บริษัทเก็บรวบรวมจากแหล่งอื่นหรือบุคคลอื่นใดโดยถูกต้องตามกฎหมาย
ในกรณีที่บริษัทมีความจำเป็นที่จะต้องเก็บรวบรวม ใช้ หรือ เปิดเผยข้อมูลส่วนบุคคลของท่าน แตกต่างไปจากที่ระบุในนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ บริษัทจะแจ้งให้ท่านทราบถึงลักษณะที่แตกต่างออกไปขณะหรือก่อนที่มีการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
การที่ท่านเข้าใช้บริการ ให้ถือว่าท่านได้อ่านและเข้าใจในนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้แล้ว
ประเภทของข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม ใช้ หรือเปิดเผย
· ข้อมูลตามบัตรประชาชน หนังสือเดินทาง และ/หรือหลักฐานอื่นที่ออกโดยราชการ เช่น เลขประจำตัวประชาชน ชื่อ นามสกุล คำนำหน้าชื่อ
· ข้อมูลที่จำเป็นในการติดต่อท่าน เช่น ที่อยู่ที่สามารถติดต่อได้ หมายเลขโทรศัพท์เคลื่อนที่ อีเมล
· ลักษณะเฉพาะของบุคคล เช่น สถานภาพ ข้อมูลชีวภาพ (Biometrics)
· ข้อมูลเกี่ยวกับการศึกษาและ/หรือการทำงาน
· ข้อมูลเกี่ยวกับสถานะทางการเงิน เช่น บันทึกรายการธุรกรรมที่ทำผ่านบัญชี Kwamdee plus หรือบริการอื่น ๆ ข้อมูลบัตรเครดิต ข้อมูลบัญชีเงินฝาก
· ข้อมูลเกี่ยวกับอุปกรณ์ ระบบงาน และพฤติกรรมการใช้บริการของท่าน รวมถึงแต่ไม่จำกัดเพียง หมายเลขที่ใช้สำหรับระบุตัวตนของอุปกรณ์ที่เชื่อมต่ออยู่บนเครือข่าย (เช่น IP address, MAC address, Device ID) หมายเลขบัญชีโฆษณาที่เชื่อมต่ออยู่บนอุปกรณ์เคลื่อนที่ (Advertisement ID) ข้อมูลเครือข่าย ข้อมูลการเชื่อมต่อ ข้อมูลเกี่ยวกับระบบปฏิบัติการอุปกรณ์เคลื่อนที่ ข้อมูลผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่ ตำแหน่งที่อยู่ รายละเอียดการใช้งานอุปกรณ์ คุกกี้ (cookie) ข้อมูลสมุดโทรศัพท์ (phonebook) ข้อมูลการใช้งานแอปพลิเคชัน ข้อมูลทางเทคนิค รุ่นและประเภทของอุปกรณ์ รูปภาพ รูปถ่าย และอัลบั้มรูป
ทั้งนี้ เพื่อความจำเป็นในการให้บริการเพิ่มเติมนอกเหนือจากบริการพื้นฐานของบริษัท หรือกรณีเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลที่ความละเอียดอ่อน (Sensitive Personal Data) นอกเหนือจากที่ระบุข้างต้น บริษัทจะดำเนินการภายใต้ฐานต่าง ๆ ตามกฎหมายกำหนดเท่านั้น โดยบริษัทจะแจ้งวัตถุประสงค์ และประเภทของข้อมูลส่วนบุคคลที่ต้องการเก็บรวบรวม ใช้ หรือเปิดเผย ก่อนหรือขณะที่จะมีการดำเนินการดังกล่าว
สถานที่:
เราจำเป็นต้องได้รับข้อมูลตำแหน่งโดยประมาณของคุณเพื่อให้บริการระบุตำแหน่งแก่คุณ และให้ข้อมูลเกี่ยวกับร้านบริการและสถาบันสินเชื่อใกล้เคียงแก่คุณ เราสัญญาว่าจะรับข้อมูลตำแหน่งโดยประมาณของคุณเมื่อคุณใช้ฟังก์ชันที่เกี่ยวข้องและปกป้องความเป็นส่วนตัวของคุณอย่างเคร่งครัดสมุดที่อยู่:
เพื่อปรับปรุงคุณภาพการบริการของเราและช่วยให้คุณติดต่อทีมบริการลูกค้าของเราได้ง่ายขึ้น เราจำเป็นต้องเข้าถึงสมุดที่อยู่ของคุณ เราสัญญาว่าจะเข้าถึงสมุดที่อยู่โดยได้รับความยินยอมจากคุณเท่านั้น และจะไม่ใช้ข้อมูลติดต่อของคุณเพื่อวัตถุประสงค์อื่นใด
กล้อง:
เราจำเป็นต้องเข้าถึงกล้องของคุณเพื่อให้คุณสามารถจับภาพและอัปโหลดเอกสารประจำตัวที่จำเป็นและเอกสารที่จำเป็นอื่น ๆ ได้อย่างง่ายดาย การเข้าถึงกล้องของคุณมีไว้เพื่อจุดประสงค์นี้เท่านั้น และเราจะตรวจสอบให้แน่ใจว่าภาพถ่ายและข้อมูลของคุณจะถูกเก็บไว้อย่างปลอดภัย
คลังภาพ:
เราจำเป็นต้องเข้าถึงแกลเลอรีของคุณเพื่อให้คุณสามารถอัปโหลดเอกสารและข้อมูลที่จำเป็นเพื่อดำเนินการสมัครขอสินเชื่อและกระบวนการอื่น ๆ ที่เกี่ยวข้องให้เสร็จสมบูรณ์ เรามุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของคุณอย่างเคร่งครัด และข้อมูลแกลเลอรีของคุณจะถูกนำมาใช้เมื่อจำเป็นเท่านั้น และจะไม่ถูกนำมาใช้เพื่อวัตถุประสงค์อื่น
ฐานในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน และวัตถุประสงค์ในการดำเนินการ
บริษัทจะดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ภายใต้ฐานตามกฎหมายและดำเนินการตามวัตถุประสงค์ดังต่อไปนี้
· ฐานความจำเป็นต่อการปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญา หรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญา รวมถึงแต่ไม่จำกัดเพียง
o การพิจารณาคำขอลงทะเบียนเข้าใช้แอปพลิเคชัน
o การอื่นใดที่จำเป็นในการให้บริการที่ดีที่สุดจากบริษัท
o การให้บริการช่วยเหลือ ติดต่อสอบถามข้อมูล ตอบรับคำร้องขอ รับข้อมูลข่าวสาร หรือเพื่อการติดต่อสอบถามเกี่ยวกับบริการ
o การพัฒนา ปรับปรุงคุณภาพการให้บริการ เพิ่มประสิทธิภาพในการให้บริการ และการอำนวยความสะดวกให้แก่ท่านในการเข้ารับบริการ
o การประมวลผลข้อมูลเพื่อให้บริการ
o วัตถุประสงค์ใดๆ ที่เกี่ยวข้องกับการให้บริการ ที่ท่านอาจสนใจหรือเป็นประโยชน์แก่ท่าน ผ่านช่องทางการแจ้งเตือนของบริษัท และช่องทางการติดต่อที่ท่านได้แจ้งไว้ ซึ่งบริษัทมีสิทธิโดยชอบตามกฎหมายอันเนื่องมาจากการให้บริการ
การดำเนินการอื่นใดที่เกี่ยวข้องกับการให้บริการและอาจเป็นประโยชน์ต่อท่าน
· ฐานการปฏิบัติหน้าที่ตามกฎหมาย รวมถึงแต่ไม่จำกัดเพียง
o การปฏิบัติตามกฎหมายที่เกี่ยวข้องกับบริษัท ซึ่งบริษัทมีหน้าที่ต้องปฏิบัติตาม เพื่อให้เป็นไปตามกฎหมาย ระเบียบ กฎ หลักปฏิบัติ หรือแนวทางปฏิบัติใด ๆ ที่ออกโดยหน่วยงานตามกฎหมายหรือที่มีอำนาจกำกับดูแล เช่น ประกาศของธนาคารแห่งประเทศไทย พระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน เป็นต้น โดยบริษัทอาจนำส่งข้อมูลส่วนบุคคลของท่านให้แก่ผู้ตรวจสอบภายใน/ภายนอก หน่วยงานราชการ หรือบุคคลนิติบุคคลอื่น ๆ ที่เกี่ยวข้อง
· ฐานความยินยอม รวมถึงแต่ไม่จำกัดเพียง
o เพื่อวัตถุประสงค์ในการวิเคราะห์ หรือคาดการณ์เกี่ยวกับความชื่นชอบหรือพฤติกรรมของท่าน รวมถึงการวิจัย พัฒนา ปรับปรุงผลิตภัณฑ์ และวางแผนการตลาด เพื่อให้บริษัทในเครือของบริษัท เครือเจริญโภคภัณฑ์ จำกัด นำเสนอสินค้าและบริการ สิทธิประโยชน์ รายการส่งเสริมการขาย และข้อเสนอต่าง ๆ ของบริษัทในเครือ หรือพันธมิตรทางธุรกิจของบริษัทในเครือ ที่วิเคราะห์และคัดสรรอย่างเหมาะสมกับท่าน
o วัตถุประสงค์อื่น ๆ อันชอบด้วยกฎหมายซึ่งบริษัทจะดำเนินการขอความยินยอมจากท่านเป็นคราว ๆ ไป เช่น เพื่ออำนวยความสะดวกให้แก่ท่านในการขอใช้บริการของผู้ให้บริการอื่นใดผ่านทางแอปพลิเคชัน
· ฐานประโยชน์โดยชอบด้วยกฎหมายของบริษัทหรือของนิติบุคคลอื่นที่ไม่ใช่บริษัท เว้นแต่ประโยชน์ดังกล่าวมีความสำคัญน้อยกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของท่าน รวมถึงแต่ไม่จำกัดเพียง
o การป้องกันอาชญากรรมและการฉ้อโกง
o การรักษาความปลอดภัยของระบบและเครือข่ายเพื่อให้เป็นไปตามมาตรฐานสากล
o การป้องกันการทุจริตและการรักษาความปลอดภัยแก่บัญชี Kwamdee plus ของท่าน
· ฐานประโยชน์สาธารณะที่สำคัญ โดยบริษัทได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของท่าน รวมถึงแต่ไม่จำกัดเพียง
o การพิสูจน์/ยืนยันตัวตนเพื่อป้องกันอาชญากรรมและการฉ้อโกง
o การต้องสงสัยเกี่ยวกับการสนับสนุนทางการเงินสำหรับการก่อการร้ายหรือการฟอกเงิน
นอกเหนือจากฐานตามกฎหมายและวัตถุประสงค์ข้างต้น บริษัทอาจดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านภายใต้ฐานอื่น ๆ ตามกฎหมาย เช่น เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกายหรือสุขภาพของท่าน หรือ เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ เป็นต้น โดยหากมีการดำเนินการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลภายใต้วัตถุประสงค์อื่น ๆ ตามฐานดังกล่าว บริษัทจะแจ้งให้ท่านทราบในภายหลัง
การเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอก และการเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่น
เพื่อการดำเนินการตามวัตถุประสงค์และภายใต้ฐานตามกฎหมายของบริษัทข้างต้น บริษัทอาจเก็บรวบรวมจาก และ/หรือ ใช้, ส่ง, โอน, ประมวลผล และ/หรือเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลดังต่อไปนี้
· นิติบุคคลที่มีอำนาจควบคุมบริษัท หรืออยู่ภายใต้การควบคุมของบริษัท รวมถึงบริษัทที่อยู่ภายใต้การควบคุมเดียวกันกับบริษัท
นิติบุคคล หรือบุคคลอื่นใดที่บริษัทเป็นคู่สัญญาหรือมีนิติสัมพันธ์กับบริษัท รวมถึงผู้ให้บริการอื่น ๆ ที่มีอำนาจดำเนินการใด ๆ กับข้อมูลส่วนบุคคลของท่าน ทั้งภายในและภายนอกประเทศ เช่น ผู้ให้บริการภายนอก ผู้ให้บริการ Cloud หรือคู่ค้าอื่นใดของบริษัท ทั้งนี้ บริษัทจะดำเนินการให้แน่ใจว่าผู้ให้บริการเหล่านั้นจะดำเนินการกับข้อมูลส่วนบุคคลของท่านตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและกฎหมายที่เกี่ยวข้อง
· ผู้สอบบัญชี ผู้ตรวจสอบภายนอกของบริษัท หน่วยงานราชการ ผู้รับโอนสิทธิเรียกร้อง และบุคคลหรือนิติบุคคลอื่นใด ที่บริษัทจำเป็นต้องส่งข้อมูลส่วนบุคคลให้เพื่อความจำเป็นในการปฏิบัติตามกฎหมาย
· ผู้รับโอนธุรกิจ/กิจการ ในกรณีที่บริษัทมีการควบรวม โอน ขาย ทรัพย์สิน และ/หรือกิจการทั้งหมด หรือแต่บางส่วน
ระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลของท่าน
บริษัทมีความจำเป็นที่จะต้องเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้ เป็นระยะเวลาเท่าที่จำเป็นในระหว่างที่ท่านยังใช้บริการหรือมีความสัมพันธ์อยู่กับบริษัท หรือตลอดระยะเวลาเท่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับการให้บริการของบริษัท โดยอาจเก็บรวบรวมไว้ต่อไปภายหลังจากนั้นหากมีกฎหมายกำหนดไว้ เช่น ตามกฎหมายว่าด้วยการป้องกันและปราบปรามการฟอกเงิน หรือเพื่อวัตถุประสงค์ในการพิสูจน์ตรวจสอบกรณีอาจเกิดข้อพิพาทภายในอายุความตามที่กฎหมายกำหนดเป็นระยะเวลาไม่เกิน 10 ปี
ทั้งนี้ บริษัทจะลบหรือทำลายข้อมูลส่วนบุคคล หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุถึงตัวตนของท่านได้เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าวแล้ว
สิทธิของท่านตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
ในฐานะที่ท่านเป็นเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล ท่านมีสิทธิตามกฎหมายในการดำเนินการ ดังต่อไปนี้
1. สิทธิในการเพิกถอนความยินยอมของท่าน
ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทได้ ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลท่านอยู่กับบริษัท
2. สิทธิในการขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของท่าน
ท่านมีสิทธิในการขอเข้าถึงข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัท หรือขอให้บริษัททำสำเนาข้อมูลส่วนบุคคลดังกล่าว ในรูปแบบที่สามารถอ่านหรือใช้งานได้โดยทั่วไปโดยเครื่องมือหรืออุปกรณ์ที่ทำงานโดยอัตโนมัติ หรือใช้หรือเปิดเผยข้อมูลส่วนบุคคลดังกล่าวโดยวิธีอัตโนมัติ (ในกรณีที่บริษัทจัดทำ) หรือขอให้บริษัทส่งหรือโอนข้อมูลส่วนบุคคลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่นตามที่กฎหมายกำหนด หรือขอรับข้อมูลส่วนบุคคลที่บริษัทส่งหรือโอนแก่ผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น (เว้นแต่ทางเทคนิคไม่สามารถที่จะทำได้) รวมถึงขอให้บริษัทเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทให้แก่ท่านได้
3. สิทธิในการขอให้แก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง
ท่านมีสิทธิในการขอให้บริษัทแก้ไขข้อมูลส่วนบุคลที่ท่านให้ไว้กับบริษัทที่ไม่ถูกต้อง หรือ เพิ่มเติมข้อมูลที่ไม่สมบูรณ์ ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลท่านอยู่กับบริษัท
4. สิทธิในการขอให้ลบ หรือทำลายข้อมูลส่วนบุคคล หรือแปลงข้อมูลส่วนบุคคลให้อยู่ในรูปแบบข้อมูลที่ไม่สามารถรู้ตัวบุคคลที่เป็นเจ้าของข้อมูลได้
ท่านมีสิทธิในการขอให้บริษัททำการลบ หรือทำลาย หรือแปลงข้อมูลส่วนบุคคลให้อยู่ในรูปแบบข้อมูลที่ไม่สามารถรู้ตัวบุคคลที่เป็นเจ้าของข้อมูลได้ ซึ่งข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัท ด้วยเหตุบางประการตามที่กฎหมายกำหนดได้
5. สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล
ท่านมีสิทธิในการขอให้บริษัทระงับการใช้ข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัท ด้วยเหตุบางประการตามที่กฎหมายกำหนดได้
6. สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล
ท่านมีสิทธิในการคัดค้านบริษัทในการประมวลผลข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัท ด้วยเหตุบางประการตามที่กฎหมายกำหนดได้
7. สิทธิในการร้องเรียนในกรณีที่บริษัทฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
ท่านมีสิทธิในการร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลในกรณีที่บริษัท รวมทั้ง ลูกจ้างหรือผู้รับจ้างของบริษัทฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
ทั้งนี้ ท่านสามารถติดต่อมายังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัท เพื่อยื่นคำร้องขอให้บริษัทดำเนินการตามสิทธิข้างต้นได้ที และบริษัทจะดำเนินการแจ้งให้ท่านทราบถึงผลกระทบที่จะเกิดขึ้นในการดำเนินการตามคำร้องขอนั้น อย่างไรก็ตาม บริษัทมีสิทธิปฏิเสธที่จะดำเนินการตามคำร้องขอของท่าน ในกรณีที่มีกฎหมายกำหนดไว้ รวมถึงบริษัทอาจเก็บค่าธรรมเนียมในการดำเนินการตามคำขอโดยพิจารณาจากค่าใช้จ่ายในการบริหารจัดการและนโยบายภายในบริษัท
การรักษาความปลอดภัย
บริษัทรับทราบและตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลของท่าน บริษัทจึงได้ปรับปรุงและพัฒนาระบบรักษาความปลอดภัยข้อมูลส่วนบุคคลของท่านให้สอดคล้องกับกฎหมาย และทันสมัยและปลอดภัยตามหลักมาตรฐานสากลตลอดเวลา บริษัทจะดำเนินการอย่างเต็มความสามารถเพื่อปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ และเน้นย้ำให้เจ้าหน้าที่ รวมถึงผู้ประมวลผลข้อมูลส่วนบุคคลของบริษัทที่มีสิทธิเข้าถึงข้อมูลส่วนบุคคล หรือมีหน้าที่ตามกฎหมาย รักษาและเคารพซึ่งความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
ในกรณีที่ทางบริษัทมีความจำเป็นหรือต้องโอนข้อมูลส่วนบุคคลใด ๆ ของท่านไปยังต่างประเทศ บริษัทจะใช้มาตรฐานตามที่กฎหมายในเรื่องการคุ้มครองข้อมูลส่วนบุคคลกำหนด และบริษัทจะไม่โอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทางที่ไม่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามหลักเกณฑ์ที่กฎหมายกำหนด เว้นแต่ท่านยินยอมให้บริษัทส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทางดังกล่าวได้ อย่างไรก็ตาม ในกรณีที่ข้อมูลส่วนบุคคลของผู้ใช้บริการถูกจารกรรมทางคอมพิวเตอร์ ไม่ว่าด้วยวิธีการเจาะระบบ (hacking) ขโมย สำเนา หรือทำลายฐานข้อมูล ทำลายรหัสลับส่วนตัว (password mining) หรือวิธีการอื่นใด ซึ่งมิใช่ความผิดของบริษัท บริษัทมีสิทธิที่จะปฏิเสธความรับผิดใดๆ อันเป็นผลมาจากการกระทำดังกล่าว
ข้อมูลส่วนบุคคลของผู้หย่อนความสามารถตามกฎหมาย
หากท่านเป็นผู้หย่อนความสามารถตามกฎหมาย อันได้แก่ ผู้เยาว์ (อายุต่ำกว่า 20 ปีบริบูรณ์ หรือยังไม่บรรลุนิติภาวะตามกฎหมาย) บุคคลที่ถูกศาลสั่งให้เป็นบุคคลเสมือนไร้ความสามารถ หรือบุคคลไร้ความสามารถตามกฎหมาย ในกรณีที่บริษัทมีความจำเป็นต้องเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน บริษัทจะดำเนินการให้เป็นไปตามขั้นตอนที่เหมาะสม และตามมาตรฐานที่กฎหมายกำหนดเท่านั้น
ภาษา
การแปลนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ ไม่ว่าจะแปลเป็นภาษาใดก็ตาม มีไว้เพื่ออำนวยความสะดวกของท่านเท่านั้น ไม่ได้มีเจตนาใด ๆ ที่จะปรับเปลี่ยนนโยบายการคุ้มครองข้อมูลส่วนบุคคล ในกรณีที่มีข้อความขัดแย้งกันระหว่างฉบับภาษาไทยและภาษาอื่น ๆ ที่ไม่ใช่ภาษาไทย ให้ยึดข้อความตามภาษาไทย
การปรับปรุงนโยบาย
อนึ่ง การใช้บริการใด ๆ ของบริษัท ถือเป็นการยอมรับนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ บริษัทอาจแก้ไขหรือเพิ่มเติมนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ได้ไม่ว่าในเวลาใด ๆ และแจ้งให้ท่านทราบ การที่ท่านใช้บริการแอปพลิเคชัน หรือบริการใด ๆ ภายหลังจากที่มีการประกาศแก้ไขหรือเพิ่มเติมดังกล่าวย่อมถือเป็นการยอมรับการแก้ไขหรือเพิ่มเติมแต่ละครั้ง
การติดต่อ
หากท่านมีคำถาม ข้อร้องเรียน การขอเข้าถึง หรือข้อสงสัยใด ๆ เกี่ยวข้องกับนโยบายการคุ้มครองข้อมูลส่วนบุคลนี้ และ/หรือต้องการตรวจสอบว่าข้อมูลส่วนบุคคลใดที่ท่านให้ไว้กับบริษัทอยู่ในความครอบครองของบริษัท ท่านสามารถติดต่อบริษัทได้ตามรายละเอียดต่อไปนี้
MACKALE GROUP PUBLIC COMPANY LIMITED
ที่อยู่ : 45/360 หมู่ที่ 6 ถนนลำลูกกา ตำบลคูคต อำเภอลำลูกกา จ.ปทุมธานี 12130`,
    };
  },
  created() {
    this.gotoTop();
    if (this.$route.query.id) {
      this.currentTab = this.$route.query.id;
    }
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    changeImage(image) {
      this.currentImage = image;
    },
    changeTab(tab) {
      this.currentTab = tab;
      if(tab === '1'){
        this.$router.push('/privacypolicy');
      }
      if(tab === '3'){
        this.$router.push('/Authorization');
      }
      if(tab === '4'){
        this.$router.push('/businessStatement');
      }
    },
    gotoLink1() {
      window.open("https://app.bot.or.th/BOTLicenseCheck/License/ViewDetail?id=295", "_blank")
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img1BoxContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-left: 45px;
  margin-right: 45px; */
  /* position: relative; */
}

.rightImgBox2 {
  /* position: absolute; */
  /* padding: 100px 0; */
  padding-top: 40px;
  padding-bottom: 100px;
}

.box1 {
  min-width: 230px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  /* position: absolute;
  top: 60px;
  left: 40px; */
  padding: 10px 10px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  margin-left: 98px;
  margin-top: 40px;
  margin-right: 40px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  /* 添加指针样式以表示可点击 */
  color: #333;
  /* 设置默认字体颜色 */
  border-radius: 5px;
  padding: 15px 5px;
  text-align: center;
}

a {
  text-decoration: none;
}

a:hover {
  color: #ff6600;
  /* 设置链接悬停时的颜色 */
}

/* 新增样式：点击后的效果 */
li.clicked {
  background-color: #4263ff;
  /* 点击后背景色为蓝色 */
  color: #fff;
  /* 点击后字体颜色为白色 */
  border-radius: 15px 15px 0px 15px;
}

.img1Box {}

.imgItem1 {
  margin-top: 10px;
  margin-bottom: 40px;
}

.imgItem1,
.imgItem2 {
  width: 80%;
  margin-right: 70px;
}

.imgItem3 {
  width: 80%;
  margin-right: 174px;
}

.textBox {
  width: 80%;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 4400px;
  padding: 60px 100px;
  font-size: 18px;
  color: #333333;
  /* height: 5500px; */
  height: 100%;
  margin-bottom: 200px;
}

.textContext {
  white-space: pre-wrap;
}
.rightImgBox2{
  position: relative;
}
.clickBox1 {
  position: absolute;
  background: transparent;
  width: 570px;
  height: 94px;
  top: 22%;
  left: 18%;
  cursor: pointer;
  /* border: 1px solid red; */
  border-radius: 40px;
}
</style>
