<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <div class="img1BoxContent">
          <div class="leftBox">
            <img :src="importedImg1" alt="" class="imgItem1" />
          </div>
          <div class="rightBox">
            <!-- <img :src="importedImg2" alt="" class="imgItem2" /> -->
            <div class="card">
              <div class="form">
                <a-input class="inputBox" v-model="formValues.mailName" placeholder="หมายเลขอีเมลของคุณ">
                  <template #suffix>
                    <img :src="mailIcon" />
                  </template>
                </a-input>
                <a-textarea class="textareaBox" v-model="formValues.remark" placeholder="คำถามที่คุณต้องการถาม"
                  :auto-size="{ minRows: 23, maxRows: 23 }" />
                <div><a-button type="primary" :disabled="formValues.mailName === '' || formValues.remark === ''"
                    :class="{ submitButton: true, btnBgcolor: formValues.mailName !== '' && formValues.remark !== '' }"
                    @click="submitFn" :loading="isLoading">ส่ง</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <!-- <img :src="importedImg2" alt="" class="imgItem2" /> -->
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <!-- <img :src="importedImg3" alt="" class="imgItem3" /> -->
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <!-- <img :src="importedImg4" alt="" class="imgItem4" /> -->
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
      </div>
    </div>
    <!-- <div class="imgBox6 min-w-1400">
      <div class="img6Box max-w-1400">
        <img :src="importedImg6" alt="" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7Box max-w-1400">
        <img :src="importedImg7" alt="" class="imgItem7" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import "./index.css";
import importedImg1 from "@/page/markale/components/img/3_1.png";
import mailIcon from "@/page/markale/components/img/mailIcon.png";
// import importedImg2 from "@/page/markale/components/img/3_2.png";
// import importedImg3 from "@/page/markale/components/img/2_3.png";
// import importedImg4 from "@/page/markale/components/img/2_4.png";
// import importedImg5 from "@/page/markale/components/img/2_5.png";
// import importedImg6 from "@/page/markale/components/img/2_6.png";
// import importedImg7 from "@/page/markale/components/img/2_7.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      mailIcon: mailIcon,
      // importedImg2: importedImg2,
      // importedImg3: importedImg3,
      // importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      altText: "图片",
      formValues: {
        mailName: '',
        remark: ''
      },
      isLoading: false,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitFn() {
      if (this.formValues.mailName === '' || this.formValues.remark === '') {
        message.info("必填项不能为空");
        return
      }
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        message.success("success");
      }, 2000)
    }
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img1BoxContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: 40px; */
  margin-bottom: 500px;
  /* border: 1px solid red; */
}

.leftBox {
  margin-left: 180px;
  margin-top: 40px;
  margin-right: 56px;
}

.rightBox {
  width: 100%;
  /* height: 100px; */
  /* border: 1px solid red; */
}

.card {
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  padding: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: end;
}


.inputBox {
  /* font-size: 18px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  height: 50px; */
}

.inputBox>>>.ant-input {
  font-size: 18px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  height: 56px;
}

/* .inputBox>>>.ant-input:focus {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
}

.inputBox>>>.ant-input:hover  {
  border: none;
  border-bottom: 1px solid white !important;
  box-shadow: 0 0 1px 2px #4263FF;
} */

.textareaBox {
  margin-top: 20px;
  border: 2px solid #cdcdcd;
  border-radius: 10px;
  font-size: 18px;
}

.submitButton {
  /* background-color: #4263ff !important; */
  border: none;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  width: 120px;
  font-size: 18px;
}

.btnBgcolor {
  background-color: #4263ff !important;
}

.submitButton:hover {
  background-color: #4263ff;
}

.imgItem2 {
  width: 80%;
  margin-top: 20px;
}
</style>
